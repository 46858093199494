.header {
    width: 100%;
    background: #000000;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    display: flex;
    justify-content: center;
}

.title1 {
    display: flex;
    justify-content: center;
    height: 8rem;
}

.title-header {
    display: flex;
    justify-content: center;
}

.title, .title1 text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #FFFFFF;
    margin-left: 3px;
}
/*                                                 sx={{marginTop:'3rem', width:'13rem'}}
 */
.menu{
    margin-top: 3rem;
    width: 13rem;
}
.icon {
    color: white;
}

.icon-sidebar {
    color: #CEB23C;
    margin:auto 0.5rem;
}

.link-bg:active {
    background-color: #FFFFFF;
}
