body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.routing {
    z-index: 1;
}

.main-container {
    display: flex;
}

.main-app-body {
    width: 100%;
}

.containerPage {
    margin-top: 80px;
}

.containerPage .row {
    margin: 0rem 1.5rem;
    display: flex;
    justify-content: space-between;
}

.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 1;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.cancel-button {
    background-color: #d2cbcb;
    border: none;
    border-radius: 2px;
    padding: 0.5rem;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    cursor: pointer;
}

.textfield {
    width: 15rem;
}

.uploadLogo {
    margin: auto 0px;
}

.error {
    color: #D32F2F;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    font-size: small;
}

.errorInput {
    color: red;
    font-size: larger;
    margin-top: 1rem;
}

.link-sidebar {
    text-decoration: none;
    color: #ceb23c
}

.link-sidebar :hover {
    color: #decf93;
}

.link {
    text-decoration: none;
    color: #6d6b6b;
}

.profile-data {
    width: 9rem;
    text-align: start;
}

.tableContainer {
    max-width: none;
}

.container-route {
    color: #a5a1a1;
    font-family: 'Poppins';
    text-transform: uppercase;
}

.modal-class {
    display: flex;
    align-items: center;
    justify-content: center;
}

.paper-class {
    background-color: white;
    border: 2px solid #ceb23c;
    padding: 16px 32px 24px;
}

.table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
}

.table td,
.table th {
    border: 1px solid #ddd;
    padding: 1px;
    text-align: center;
}

.table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.table tr:hover {
    background-color: #ddd;
}

.table th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #000000;
    color: white;
}


.input_div {
    padding: 20px 10px;
    background-color: #000000;
}

.header_name {
    font-size: 25px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;

}

.input_box {
    padding: 10px 20px;
    width: 20%;
    border-radius: 20px;

}

.form1 {
    display: flex;
    justify-content: center;
    color: #ceb23c
}

.search {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    height: 2rem;

}

.search-box {
    width: fit-content;
    height: fit-content;
    position: relative;
}

.input-search {
    height: 50px;
    width: 50px;
    border-style: none;
    padding: 10px;
    font-size: 18px;
    letter-spacing: 2px;
    outline: none;
    border-radius: 25px;
    transition: all .5s ease-in-out;
    background-color: #e8d5d5;
    padding-right: 40px;
    color: rgb(0, 0, 0);
}

.input-search::placeholder {
    color: rgba(225, 12, 12, 0.5);
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 100;
}

.input-search:focus {
    width: 300px;
    border-radius: 0px;
    background-color: transparent;
    border-bottom: 1px solid rgba(243, 0, 0, 0.5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}

.close {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    z-index: 1;
}

.form {
    display: flex;
    justify-content: center;
}

.form-input {
    display: flex;
    margin: 3rem;
    justify-content: space-around;
}

.form-input1 {
    display: flex;
    margin-bottom: 2rem;
    justify-content: space-around;
    min-width: 25rem;
}

.form-input2 {
    display: flex;
    margin-bottom: 2rem;
    justify-content: flex-start;
}

.pagination-box {
    display: flex;
    margin: 1rem 0px;
    justify-content: space-around;
}

.box {
    background-color: #f9f9f9;
    border-radius: 1rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
    padding: 1rem;
}

.form-input1 .textfield {
    margin: 0px 1rem;
    width: 15rem;
}

.form-header {
    display: flex;
    color: #ceb23c;
    justify-content: center;
    margin: 2rem auto;
    text-transform: uppercase;
}

.form-header1 {
    display: flex;
    color: #ceb23c;
    justify-content: center;
    text-transform: uppercase;
}

.form-input3 {
    display: flex;
    margin: 0px 1rem 2rem;
    justify-content: space-between;

}

.btn-create {
    height: fit-content;
    background-color: #ceb23c;
    color: black;
    font: "Poppins";
    border: none;
}

.modal-button {
    background-color: #ceb23c;
    border: none;
    border-radius: 2px;
    padding: 7px;
    min-width: 5rem;
}

.box-header {
    display: flex;
    justify-content: space-between;
}

.box-align {
    display: flex;
    justify-content: center;
    color: #ceb23c;
    margin: 2rem 0px;
}

.box-align1 {
    display: flex;
    justify-content: center;
    color: #ceb23c;
    margin-bottom: 4rem;
}

.box-align2 {
    display: flex;
    justify-content: center;
    margin-top: 4rem;
}

.box-align3 {
    display: flex;
    justify-content: center;
    color: black;
    margin-bottom: auto 0px;
}

.select {
    width: 15rem;
    display: flex;
    margin: 0 auto,
}

.box-align-end {
    display: flex;
    justify-content: flex-end;
}

.box-margin {
    margin: 0rem 1.5rem;
}

.box-margin1 {
    margin: 0rem 2rem;
}

.box-margin2 {
    margin: 2rem 2rem;
}

.form-check {
    margin: 2rem 0px;
}

.card {
    box-shadow: 3;
    height: 100%;
}

.table-width1 {
    max-width: 12rem;
}

.table-width2 {
    width: 6rem;
}

.table-width3 {
    max-width: 6rem;
    min-width: 4rem;
}

.table-width4 {
    max-width: 8rem;
}

.table-width5 {
    max-width: 7rem;
}

.table-width6 {
    width: 10rem;
    margin-right: 1rem;
}

.table-width7 {
    max-width: 15rem;
}

.table-width8 {
    text-align: start;
    padding-left: 1rem;
}

.table-width9 {
    max-width: 22rem;
    min-width: 5rem;
}

.table-width10 {
    max-width: 10rem;
    min-width: 5rem;
}

.table-width11 {
    width: 12rem;
}

.table-width12 {
    width: 10rem;
}

.table-width13 {
    width: 15rem;
}

.table-width14 {
    width: 14rem;
}

.table-width15 {
    min-width: 4rem;
}

.table-width16 {
    width: 15rem;
    margin-right: 1rem;
}

.table-red {
    color: red;
    text-align: start;
    padding-left: 1rem;
}

.table-green {
    color: green;
    text-align: start;
    padding-left: 1rem;
}

.upload {
    display: none;
}

.tree-select {
    width: 10rem
}

.cursor {
    cursor: pointer;
}

.modal-card {
    width: 25rem;
    margin-bottom: 4rem;
    padding: 0px 1rem;
    min-height: 4rem;
}

.formcontrol {
    width: 15rem;
    margin-left: 1rem;
    margin-top: 1rem;
}

.red {
    color: red;
}

.green {
    color: rgb(76, 175, 80);
}

.grey {
    color: grey
}

.black {
    color: black;
}

.label-color {
    color: #ceb23c;
    padding-left: 0.5rem;
}

.table-box {
    margin: auto 2rem
}

.sequence {
    margin: 3rem 1rem;
    display: flex;
    justify-content: space-evenly;
    max-width: 20rem;
}

.transaction {
    width: 30rem
}

.buttonBox {
    margin-top: 2rem;
}

.box-align-end-date {
    display: flex;
    justify-content: flex-end;
    margin-right: 2rem;
    margin-bottom: 1rem;
}

.statement {
    display: flex;
    justify-content: space-between;
    margin: 0px 2rem 1rem;
}

.statement-card {
    width: 20rem;
    padding: 0px 1rem;
    min-height: 4rem;
}

.orange{
    color: orange;
}